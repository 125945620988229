import './App.css';
import { Routes, Route} from 'react-router-dom';
import Home from './pages/home/home';
import { RegisterTeam } from './pages/registerTeam/registerTeam';
import { ReviewInfo } from './pages/registerTeam/reviewInfo';
import { Toaster } from 'react-hot-toast';
import Dashboard from './pages/dashboard/dashboard';
import { Fixtures } from './pages/Fixtures/fixtures';
import { TeamInfo } from './pages/teamInfo/teamInfo';
import { ContactUs } from './pages/contactUs/contactUs';
import { Faqs } from './pages/faqs/faqs';
import { Rules } from './pages/rules/rules';
import { TermsAndConditions } from './pages/Legal/termsAndConditions';
import { PrivacyPolicy } from './pages/Legal/privacyPolicy';
import { Otpverification } from './pages/otpverification/otpverification';
import { AboutUs } from './pages/aboutUs/aboutUs';


function App() {

  return (
    <div className="App">
      <Toaster position="top-center" reverseOrder={false} />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/registerteam' element={<RegisterTeam />}></Route>
        <Route path='/registerteam/review' element={<ReviewInfo />}></Route>
        <Route path='/admin/dashboard' element={<Dashboard />} />
        <Route path='/fixtures' element={<Fixtures />} />
        <Route path='/team/:teamName' element={<TeamInfo />} />
        <Route path='/contactUs' element={<ContactUs/>}/>
        <Route path='/faqs' element={<Faqs/>}/>
        <Route path='/rules' element={<Rules/>}/>
        <Route path='/terms&Conditions' element={<TermsAndConditions/>}/>
        <Route path='/privacyPolicy' element={<PrivacyPolicy/>}/>
        <Route path='/admin/dashboard/otpverification' element={<Otpverification/>}/>
        <Route path='/about-us' element={<AboutUs/>}/>
      </Routes>

    </div>
  );
}

export default App;
