import React from 'react'
import './pointsTable.css'
import { fetchPointsTable } from '../../../../utils/getDataForDashboard'
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { CustomBarLoader } from '../../../loader/loader';

export const PointsTable = () => {

  const { data: pointsTable, isLoading, error } = useQuery({ queryKey: ["pointsTable"], queryFn: fetchPointsTable });
  if (isLoading) return <CustomBarLoader/>
  if (error) return toast.error(error.message);
 
  return (
    <div className='pointsTableScreen'>
      <div className='pointsTableContainer'>
        <header>
          <p>Position</p>
          <p style={{ minWidth: '22%', textAlign: 'left' }}>Team</p>
          <p>Played</p>
          <p>Won</p>
          <p>Draw</p>
          <p>Lost</p>
          <p>GF</p>
          <p>Points</p>
        </header>
        {
          pointsTable.map((points,index)=>(
            <div key={index} className='pointsTableRow'>
                <p>{index+1}</p>
                <p style={{minWidth:'22%', textAlign:'left'}}>{points.team_name}</p>
                <p>{points.played}</p>
                <p>{points.won}</p>
                <p>{points.draw}</p>
                <p>{points.lost}</p>
                <p>{points.goal_difference}</p>
                <p>{points.total_points}</p>
            </div>
          ))
        }
      </div>
    </div>
  )
}
