import React, { useState } from 'react'
import { Navbar } from '../../components/Navbar/navbar'
import { Footer } from '../../components/Footer/footer';
import Image1 from './playing_football.svg';
import './contactUs.css'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';


export const ContactUs = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name.length === 0 || email.length === 0 || message.length === 0) {
            toast.error("Please fill out the form right.");
            return;
        }
        setLoading(true);
        let body = {
            name: name,
            email: email,
            message: message
        }
        const res = await fetch(process.env.REACT_APP_ENV_URL + '/api/admin/sendNotificationToAdmin', {
            method: 'POST',
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(body),
            credentials: 'include'
        })
        if (res.status === 200) {
            toast.success("Your query has been recorded");
            setLoading(false);
            setTimeout(()=>{
                navigate('/');
            },2000)
        } else {
            const response = await res.json();
            setLoading(false);
            toast.error("Something went wrong");
        }
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Navbar />
            <div className='contactUsContainer'>
                <div className='sectionLeft'>
                    <h1>Would love to hear from you!</h1>
                    <p>Let's Connect: We are here to help, whether you have a question, comment or feedback for us.
                        You can reach out to us through this contact form, email or social media.
                    </p>
                    <form className='form_container_contactUs' onSubmit={handleSubmit}>
                        <div className='input_wrapper_contactUs'>
                            <p>Name</p>
                            <input type="name" placeholder='' value={name} onChange={(e) => { setName(e.target.value) }} />
                        </div>
                        <div className='input_wrapper_contactUs'>
                            <p>Email Address</p>
                            <input type="email" placeholder='' value={email} onChange={(e) => { setEmail(e.target.value) }} />
                        </div>
                        <div className='input_wrapper_contactUs'>
                            <p>Text</p>
                            <textarea placeholder='' value={message} onChange={(e) => { setMessage(e.target.value) }} />
                        </div>
                        <button className='primaryButton contactSubmitButton' type='submit'>
                            {
                                loading?
                                <ClipLoader color='#fff' size={18} speedMultiplier={0.7} />
                                :
                                'Send'
                            }
                        </button>
                    </form>
                </div>
                <div className='sectionRight'>
                    <img src={Image1} alt='/' className='playing_image' />
                </div>
            </div>
            <Footer />
        </div>
    )
}
