import React from 'react'
import './loader.css'
import { BarLoader } from 'react-spinners'

export const CustomBarLoader = () => {
    return (
        <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: '#000' }}>
            <p style={{marginBottom:'1rem', fontFamily:'CustomFontLight,sans-serif'}}>Loading</p>
            <BarLoader loading={true} height={2} color={'#fff'} speedMultiplier={1} />
        </div>
    )
}

