import { React, useEffect, useState } from 'react'
import { Navbar } from '../../components/Navbar/navbar'
import { Footer } from '../../components/Footer/footer'
import { CustomBarLoader } from '../../components/loader/loader';

import './rules.css';

export const Rules = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {
                <div>
                    <Navbar />
                    <div className='rulesContainer'>
                        <div className='rulesListContainer'>
                            <p className='RuleHeading'>Rules</p>
                            <h2>Teams, Players and Apparel</h2>
                            <p>1.1 Teams are 7-a-side / 8 – a-side and unlimited rolling subs are allowed; however, substitutions
                                must only take place when the play has been halted due to infringement or the ball going out of
                                play.
                                For 7 – a – side leagues &amp; tournaments, a team register upto 10 players &amp; for a, 8-a-side leagues
                                &amp; tournaments a team can register upto 12 players.</p>
                            <p>
                                1.2. Once a team is registered for a tournament or a league, the team must play with its registered
                                players only for that particular tournament or league, if a team found in breach of this rule and fields a
                                player which is not registered to play for that team, there may a point deduction or another penalty as
                                deemed fit in discretion of FlexFooty disciplinary &amp; review committee. Only in case of an injury the
                                team manager may request the injured player to be replaced and a new player to be allowed to
                                register &amp; play for the team.
                            </p>
                            <p>1.3. Players must be at least 14 years of age</p>
                            <p>1.4. In Mixed competitions, there must always be no more than three male players on the outfield.</p>
                            <p>1.5. Uniform shirts are recommended however Bibs will be provided to the away team (2 nd team of the
                                fixture).</p>
                            <p>1.6. Goalkeepers must be identified with a different coloured shirt or bib.</p>
                            <p>1.7. Shin-pads are recommended but not compulsory.</p>
                            <p>1.8. Moulded sole boots or blades are the only type of football boots permitted; metal sprigs are not
                                permitted.</p>
                            <p>1.9. Any jewellery (such as watches) deemed dangerous by the referee must be removed upon
                                request by the referee.</p>
                            {/*  */}
                            <h2>General Play</h2>
                            <p>2.1. Teams require a minimum of 5 players to start. For mixed competitions, the maximum 3 males in
                                the outfield rule still applies.</p>
                            <p>2.2. The match ball will be provided.</p>
                            <p>2.3. The ball can move in any direction from kick-off.</p>
                            <p>2.4. There are no off-sides.</p>
                            <p>2.5. An outfield player may not play at the ball with their hand or any part of their arm, for further
                                clarification playing the ball in this instance would mean using a part of your arm to control the ball to
                                gain an advantage OR to use any part of your arm to stop opposition attacking play, only exception to
                                this rule would be if a player has their arm in a natural position not away from their body. Referee’s will
                                use this discretion while calling a foul-play for handball. Referee’s decision will be final.</p>
                            <p>2.6. When the ball goes out, it must be thrown back in properly in conjunction with Fifa Rules; throw
                                the ball with both hands from behind and over the head with both feet either on or behind the touch
                                line; all opponents must stand approximately 2 yards away from the point on the touch line from
                                where the throw-in is to be taken from.</p>
                            <p>2.7. A ball is deemed to be out of play when the whole of the ball passes over the touch line, if a small
                                part of the ball is still on the touch line the ball is deemed to be in play.</p>
                            <p>2.8. Free kicks are taken from where an offence is committed; all opponents must stand
                                approximately 10 yards away from where the free kick is to be taken from.</p>
                            <p>
                                2.9. Opposition players must stand 5 yards from the ball during at kick-off.
                            </p>
                            {/*  */}
                            <h2>The Goalkeepers Area and Penalty Area</h2>
                            <p>3.1. The goalkeeper must throw the ball into play after handling the ball; i.e. if the goalkeeper has
                                caught the ball with their hands and is deemed to be in control of the ball, then they cannot kick the
                                ball out of their hands, this rule is in place to prevent a player behind the opposition’s defence from
                                gaining advantage as there is no offside.</p>
                            <p>3.2. Goal kicks are taken as a place kick; however, it doesn’t necessarily have to be taken by the
                                goalkeeper.</p>
                            <p>3.3. The goalkeeper cannot handle a ball deliberately passed back from a team member unless it is
                                off the player&#39;s head.</p>
                            <p>3.4. Any offence committed inside the penalty area will result in a penalty being awarded to the
                                attacking team.</p>
                            {/*  */}
                            <h2>Fouls and Infringements</h2>
                            <p>4.1. The referee’s decision is final.</p>
                            <p>4.2. Aggressive or violent conduct, foul play, referee abuse, spitting at others, and offensive speech
                                will not be tolerated, and this may result in the player(s) found guilty of misconduct to be awarded a
                                straight red card or a yellow card at the referee’s discretion and the severity of the offence committed.</p>
                            <p>4.3. A free kick or penalty may be awarded to the opposing team if a player commits any of the
                                following offences:
                                <ul>
                                    <li>Uses excessive force when taking or attempting to take the ball from an opponent</li>
                                    <li>Slides when taking or attempting to take the ball from an opponent; sliding tackles are not
                                        allowed for further clarification a slide is considered to be a tackle if there is a player of the
                                        opposing team within close proximity of the player who has slid. If there is no player within
                                        close proximity a player can slide to prevent the ball from going out of play or to block a
                                        shot.</li>
                                    <li>Pushes or holds an opponent.</li>
                                    <li>Handles the ball.</li>
                                    <li>Plays in a dangerous manner.</li>
                                    <li>Impedes the progress of an opponent.</li>
                                    <li>Prevents the goalkeeper from releasing the ball from his hands.</li>
                                    <li>Tackles an opponent from behind and makes contacts with the player and the ball; when
                                        tackling from behind there must be clean challenge on the ball without making contact with
                                        the opposition player.</li>
                                    <li>Uses Shoulder force on the back of an opposition player.</li>
                                    <li>In Mixed-Divisions no form of force is allowed when attempting to take the ball from an
                                        opponent including shoulder force on an opponent shoulder.</li>
                                </ul>
                            </p>
                            <p>4.4. Booking offences (yellow card) generally includes but is not limited to:
                                <ul>
                                    <li>unsporting behaviour.</li>
                                    <li>dissent towards the referee by word or action.</li>
                                    <li>persistent infringement of the rules of Flexfooty rules.</li>
                                    <li>delaying the restart of play from a free kick or kick-off.</li>
                                    <li>failure to respect the required distance when play is restarted with a free kick, corner kick or
                                        throw-in unless the restart is taken quickly.</li>
                                </ul>
                            </p>
                            <p>4.5. Sending-off offences (red card) generally includes but is not limited to:
                                <ul>
                                    <li>serious foul play</li>
                                    <li>violent conduct</li>
                                    <li>spitting at an opponent or any other person</li>
                                    <li>denying the opposing team a goal or an obvious goal scoring opportunity by deliberately
                                        handling the ball or impeding an attacking player using excessive force.</li>
                                    <li>denying an obvious goal scoring opportunity to an opponent moving towards the player’s goal
                                        by an offence punishable by a free kick or a penalty goal</li>
                                    <li>using offensive, insulting or abusive language and/or gestures</li>
                                    <li>receiving a second caution in the same match</li>
                                </ul>
                            </p>
                            <p>4.6. Sent-off players (red card) must leave the vicinity of the field of play and may not return to the
                                game</p>
                        </div>

                    </div>
                    <Footer />
                </div>
            }
        </>

    )
}
