import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import './sideBar.css'
import { IoCloseOutline } from "react-icons/io5";

export const SideBar = ({ data }) => {
    let setSideBarActive = data[1];
    const navigate = useNavigate();
    const location = useLocation();
    const scrollToDiv = (e, divId) => {
        e.preventDefault();
        if (location.pathname === "/") {
            setSideBarActive(false);
            const element = document.getElementById(divId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
        else {
            navigate("/", { state: 'tournamentsSliderContainer' });
        }

    }
    return (
        <div className={`sideNavBarContainer ${data[0] ? 'active' : ''}`}>
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '0 0.5rem' }}>
                <IoCloseOutline size={28} onClick={() => { setSideBarActive(false) }} />
            </div>
            <div className='sideNavBarMenuContainer'>
                <p onClick={()=>{navigate('/about-us')}}>About Us</p>
                <p onClick={() => { navigate('/contactUs') }}>Contact Us</p>
                <p onClick={() => { navigate('/faqs') }}>FAQs</p>
                <p onClick={() => { navigate('/fixtures') }}>Fixtures</p>
                <p onClick={() => { navigate('/registerteam') }}>Register Team</p>
                <p onClick={() => { navigate('/rules') }}>Rules</p>
                <p onClick={(e) => { scrollToDiv(e, "tournamentsSliderContainer") }}>Tournaments</p>
                <p onClick={()=>{navigate('/terms&Conditions')}}>Terms & Conditions</p>
                <p onClick={()=>{navigate('/privacyPolicy')}}>Privacy Policy</p>
            </div>
        </div>
    )
}
