import React from 'react'
import { useAuthContext } from '../context/authContext';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export const AssignManager = () => {
    const { managerFirstName, setManagerFirstName,
        managerLastName, setManagerLastName,
        managerEmail, setManagerEmail,
        managerContactNumber, setManagerContactNumber
    } = useAuthContext();

    const handlePhoneChange = (value, countryData) => {
        const rawPhoneNumber = value.replace(`${countryData.dialCode}`, '');
        setManagerContactNumber(`+${countryData.dialCode}-${rawPhoneNumber}`);
    }
    return (
        <div className='form'>
            <div className='input_wrapper'>
                <p>First Name</p>
                <input type="text" placeholder='' value={managerFirstName} onChange={(e) => { setManagerFirstName(e.target.value) }} />
            </div>
            <div className='input_wrapper'>
                <p>Last Name</p>
                <input type="text" placeholder='' value={managerLastName} onChange={(e) => { setManagerLastName(e.target.value) }} />
            </div>
            <div className='input_wrapper'>
                <p>Email Address</p>
                <input type="email" placeholder='' value={managerEmail} onChange={(e) => { setManagerEmail(e.target.value) }} />
            </div>
            <div className='input_wrapper'>
                <p>Contact Number</p>
                <PhoneInput placeholder='' value={managerContactNumber} onChange={handlePhoneChange} dropdownStyle={{ background: '#0b0b0b', color: 'crimson' }} buttonStyle={{ background: "transparent", border: 'none' }} country={'nz'} />
            </div>
        </div>
    )
}
