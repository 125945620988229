import React, { useEffect, useState } from 'react'
import { MdOutlineKeyboardBackspace, MdDelete } from "react-icons/md";
import { IoIosArrowRoundForward } from "react-icons/io";
import toast from 'react-hot-toast';

export const EditFixture = (props) => {

    console.log('Props', props.data);
    const updateFixture = props.data[0];
    const setUpdateFixture = props.data[1];
    const [teamaGoals, setTeamAGoals] = useState(undefined);
    const [teambGoals, setTeamBGoals] = useState(undefined);
    const [newDate, setNewDate] = useState(undefined);
    const [newTime, setNewTime] = useState(undefined);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const handleUpdateFixtureResult = async () => {
        if (teamaGoals == undefined || teambGoals == undefined) {
            toast.error('Please enter valid goal number');
            return;
        }
        const body = {
            match_id: updateFixture.match_id,
            teamA_goal: parseInt(teamaGoals),
            teamB_goal: parseInt(teambGoals)
        }
        console.log(body);
        const response = await fetch(process.env.REACT_APP_ENV_URL + '/api/admin/updateFixtureResult', {
            method: 'PUT',
            headers: { 'Content-Type': "application/json" },
            body: JSON.stringify(body),
            credentials: 'include'
        });

        if (response.status == 200) {
            toast.success('Fixture Status Updated Successfully');
            setTimeout(() => {
                setUpdateFixture(null);
            }, 2000)
        } else {

        }


    }

    const handleFixtureScheduleUpdate = async () => {
        if (updateFixture.status) {
            toast.error('Match has already been played');
            return;
        }
        let nd = newDate; let nt = newTime;
        if (nd == undefined) nd = updateFixture.match_date;
        if (nt == undefined) nt = updateFixture.match_time;

        if (nd == updateFixture.match_date && nt == updateFixture.match_time) {
            toast.error("Match already scheduled");
            return;
        }

        let body = {
            match_id: updateFixture.match_id,
            date: nd,
            time: nt
        }
        const response = await fetch(process.env.REACT_APP_ENV_URL + '/api/admin/updateFixtureDateTime', {
            method: 'PUT',
            headers: { 'Content-Type': 'Application/json' },
            body: JSON.stringify(body),
            credentials: 'include'
        });

        if (response.status == 200) {
            toast.success('Match Schedule Updated');
            setTimeout(() => {
                setUpdateFixture(null);
            }, 2000)
        } else {
            const res = await response.json();
            toast.error(res.error ? res.error : 'Error updating schedule')
        }
    }

    const handleDeleteFixture = () => {
        toast((t) => (
            <span style={{ color: '#D22B2B', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                Do you want to delete ?
                <button onClick={() => toast.dismiss(t.id)}
                    style={{
                        border: 'none', backgroundColor: '#000', padding: '0.5rem', marginLeft: '0.3rem',
                        width: '4rem',
                        borderRadius: '0.7rem',
                        cursor: 'pointer'
                    }}
                >
                    Yes
                </button>
            </span>
        ), {
            style: {
                backgroundColor: '#71797e3f',
                width: '30%'
            }
        });
    }

    return (
        <div className='editFixtureContainer'>
            <button onClick={() => { setUpdateFixture(null) }}>
                <MdOutlineKeyboardBackspace style={{ marginRight: '0.5rem' }} />
                Back
            </button>
            <div className='updateStatusSection'>
                <div>
                    <h2 style={{ marginBottom: '2rem' }}>Update Result</h2>
                    <div>
                        <p>Goals by {updateFixture.teama}</p>
                        <IoIosArrowRoundForward size={18} style={{ marginLeft: '1rem' }} />
                        <input type="text" placeholder='0' value={teamaGoals} onChange={(e) => { setTeamAGoals(e.target.value) }} style={{ border: 'none' }} />
                    </div>
                    <div>
                        <p>Goals by {updateFixture.teamb}</p>
                        <IoIosArrowRoundForward size={18} style={{ marginLeft: '1rem' }} />
                        <input type="text" placeholder='0' value={teambGoals} onChange={(e) => { setTeamBGoals(e.target.value) }} style={{ border: 'none' }} />
                    </div>
                    <button className='updateButton' onClick={handleUpdateFixtureResult}>Update</button>
                </div>
                <div>
                    <h2 style={{ marginBottom: '2rem' }}>Update Schedule</h2>
                    <div>
                        <p style={{ minWidth: '25%' }}>Match date</p>
                        <IoIosArrowRoundForward size={18} style={{ marginLeft: '1rem' }} />
                        <input type="text" placeholder={`${updateFixture.match_date.split('-').reverse().join('-')}`} style={{ width: '25%', border: 'none' }} value={newDate} onChange={(e) => { setNewDate(e.target.value) }} />
                    </div>
                    <div>
                        <p style={{ minWidth: '25%' }}>Match time</p>
                        <IoIosArrowRoundForward size={18} style={{ marginLeft: '1rem' }} />
                        <input type="text" placeholder={updateFixture.match_time} style={{ width: '25%', border: 'none' }} value={newTime} onChange={(e) => { setNewTime(e.target.value) }} />
                    </div>
                    <button className='updateButton' onClick={handleFixtureScheduleUpdate}>Update</button>
                </div>
            </div>
            <button className='deleteButton' onClick={handleDeleteFixture}>
                <MdDelete fill='#D22B2B' size={24} style={{ marginRight: '0.5rem' }} />
                Delete Fixture
            </button>
        </div>
    )
}
