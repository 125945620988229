import React, { useEffect } from 'react'
import './home.css';
import { useLocation } from 'react-router-dom';
import { HeroSection } from '../../components/landingPage/heroSection';
import { TournamentsSlider } from '../../components/landingPage/tournamentsSlider';
import { Footer } from '../../components/Footer/footer';

export default function Home() {
    return (
        <div className='home_screen'>
            <HeroSection />
            <TournamentsSlider />
            <Footer />
        </div>
    )
}
