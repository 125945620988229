import React, { useEffect } from 'react'
import {Navbar} from '../../components/Navbar/navbar';
import { Footer } from '../../components/Footer/footer';
import './fixtures.css'
import { useQuery } from '@tanstack/react-query';
import { fetchFixturesForUsers } from '../../utils/helperFunctions';
import { useNavigate } from 'react-router-dom';
import { CustomBarLoader } from '../../components/loader/loader';
import { ApiRateLimiterError } from '../../components/error/apiRateLimiterError';

export const Fixtures = () => {

  const navigate = useNavigate();
  const isMobileScreen=window.innerWidth<=1000?true:false;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const { data: userFixtures, isLoading, error } = useQuery({ queryKey: ["UserFixtures"], queryFn: fetchFixturesForUsers });
  if (isLoading) return <CustomBarLoader/>
  if (error) return <ApiRateLimiterError/>

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    })
  }

  return (
    <div>
      <Navbar/>
      <div className='fixturesListContainer'>
        {userFixtures!=null && userFixtures.map((arr, index) => (
          <div className='fixtureDateCard' key={index}>
            <h2 style={{ marginBottom: '1rem' }}>{formatDate(arr[0].match_date)}</h2>
            <div style={{ width: '100%' }}>
              {arr.map((item, index) => (
                <div className='fixtureRow'>
                  <p style={{ minWidth: '20%' }} className='clickableteam' onClick={() => { navigate(`/team/${item.teama}`) }}>{item.teama}</p>
                  <div style={{ display: 'flex', width:isMobileScreen? '15%':'10%', height: '1.4rem', justifyContent:isMobileScreen?'space-evenly':'center', backgroundColor: '#0b0b0b', padding: '0 0.5rem', borderRadius: '0.5rem', alignItems: 'center ' }}>
                    <p style={{ fontFamily: 'CustomFont, sans-serif' }} >{item.teama_goal}</p>
                    <p style={{ fontFamily: 'CustomFont, sans-serif' }}>-</p>
                    <p style={{ fontFamily: 'CustomFont, sans-serif' }} >{item.teamb_goal}</p>
                  </div>
                  <p style={{ minWidth: '20%', textAlign: 'right' }} className='clickableteam' onClick={() => { navigate(`/team/${item.teamb}`) }}>{item.teamb}</p>
                  <p style={{ minWidth: '20%', textAlign: 'right' }}>{item.match_time}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
        {userFixtures===null || userFixtures.length===0 ? <h2>Fixtures - To be confirmed</h2>:<></>}
      </div>
      <Footer/>
    </div>
  )
}
