import React from 'react'
import { Fixtures } from './internalComponents/Fixtures/fixtures'
import { PointsTable } from './internalComponents/PointsTable/pointsTable'
import { useAuthContext } from '../../context/authContext'
import { Teams } from './internalComponents/Teams/teams'
import { Venues } from './internalComponents/Venues/venues'
import {Divisions} from './internalComponents/Divisions/divisions'

export const InfoElements = () => {
    const { currentAdminMenu } = useAuthContext();
    return (
        <div className='infoElementsContainer'>
            {
                currentAdminMenu===null?
                <Fixtures />
                :
                currentAdminMenu==='PointsTable'?
                <PointsTable />
                :
                currentAdminMenu==='Teams'?
                <Teams/>
                :
                currentAdminMenu==='Venues'?
                <Venues/>
                :
                currentAdminMenu==='Divisions'?
                <Divisions/>
                :
                <></>
            }
        </div>
    )
}
