import React from 'react'
import './legal.css'
import { Navbar } from '../../components/Navbar/navbar'
import { Footer } from '../../components/Footer/footer'

export const PrivacyPolicy = () => {
    return (
        <div className='legalContainerPage'>
            <Navbar />
            <div className='legal_info_container'>
                <h1>Privacy Policy Statement.</h1>
                <p>
                    This privacy policy statement describes how FlexFooty collect, maintains, uses and shares the
                    information collected from its users.
                </p>
                <h2 style={{ marginTop: '2rem' }}>1. SCOPE</h2>
                <p>
                    This privacy policy covers how FlexFooty or FlexFooty affiliated company collects, maintains and
                    shares data for users who interact with us on our websites on any device, through an application on
                    your mobile device or in person (including via phone).
                </p>
                <h2 style={{ marginTop: '2rem' }}>
                    2. PERSONAL DATA
                </h2>
                <p>
                    Personal Data means the data that directly identifies you such as your name and also the data which
                    does not directly identify but that can be reasonably used to identify you.
                    We may collect personal data from users in different ways which include but may not be limited to;
                    when a user registers on our site(or application) advertises or endorses their products or services on
                    our website(or application), subscribe to an electronic (or physical) newsletter or may engage with us
                    in regards with any other activities, services or features we make available on our website.<br /><br />
                    Users may however visit our site anonymously if they wish to do so.
                </p>
                <h2 style={{ marginTop: '2rem' }}>
                    3. NON-PERSONAL DATA
                </h2>
                <p>
                    Non-personal data or information may include technical information such as the browser used to
                    access our website, or the type of mobile device used to engage with our application. This may also
                    include the operating system of the device, the internet service providers, location information or any
                    similar technical information which may assist to enhance our user experience.
                </p>
                <h2 style={{ marginTop: '2rem' }}>4. COOKIES</h2>
                <p>
                    Our Website may use “cookies” to enhance user experience, a cookie is a web based file which is
                    stored on the hard drive of an electronic device when a user visits a website, this file “cookie” records
                    information when a user visits a website, in this way when a website already recognises a user if they
                    had browsed the website before. This is used to keep a record of items or web pages which the user
                    may have been of interest to the user on previous occasions.<br /><br />
                    Users may set their website settings to refuse cookies or to be alerted when cookies are being used
                    by a particular website; please be noted that if a user disallows cookies some parts of the website
                    may not function properly.
                </p>
                <h2 style={{ marginTop: '2rem' }}>5. HOW WE USE COLLECTED INFORMATION</h2>
                <p>FlexFooty uses personal data:
                    <ul>
                        <li>To process any transactions.</li>
                        <li>To communicate with our users.</li>
                        <li>For security reasons and fraud prevention.</li>
                        <li>To administer content promotion.</li>
                        <li>To send periodic newsletters or surveys; Users can unsubscribe to this if they wish to do so.</li>
                        <li>We may also use for other purposes with your consent.</li>
                    </ul>
                </p>
                <h2 style={{ marginTop: '2rem' }}>6. HOW WE PROTECT YOUR INFORMATION</h2>
                <p>
                    We use appropriate data collection, storing, processing practices and security measures to protect
                    data against unauthorized access, altercation disclosure and destruction of all data that we collect.
                    Confidential data exchange between the site and users is done over a SSL secure
                    communication channel and is encrypted with digital signatures or symbols.
                </p>
                <h2 style={{ marginTop: '2rem' }}>7. SHARING YOUR PERSONAL INFORMATION</h2>
                <p>
                    We do not sell, trade or rent personal data to other parties. We may share generic demographic or
                    other non-personal information with our business partners, trusted associates and advertisers for the
                    reasons as advised above, we may share your personal information with any of our trusted associates
                    only after you have provided your consent to do so. This may be done for limited purposes only such
                    as surveys or sending out newsletters.
                </p>
                <h2 style={{ marginTop: '2rem' }}>8. CHANGES TO THIS PRIVACY POLICY</h2>
                <p>
                    FlexFooty reserves the right to amend or update this privacy policy at our discretion when we feel it is
                    necessary to do so in order to comply with laws or otherwise, when we make any changes to this
                    privacy policy, we will advise our users of the same through notifications page. We advise all our
                    users to frequently check our notifications page to stay updated on our services. As a user it is your
                    responsibility to review this privacy policy periodically to stay informed on how we collect, use and
                    protect your personal data.
                </p>
                <h2>9. YOUR ACCEPTANCE TO TERMS AS STATED IN THIS PRIVACY POLICY</h2>
                <p>
                    By using this website, you signify your acceptance of this policy and terms of service, if you continue
                    to use our website following any changes to this policy, it will be deemed as your acceptance to those
                    changes.<br />
                    Form submissions
                    by submitting any form on this website you are acknowledging, agreeing with, and understand the
                    terms, conditions, and disclaimers outlined on this page.<br />
                    Automatic opt-ins
                    By submitting any form on this website you are acknowledging and agreeing to your details being
                    added to our marketing communications and operational communications databases. We never share

                    your information with any third-parties. We only use this information for FlexFooty marketing and
                    operational purposes only.<br />
                    Opting out and Correction of personal information
                    You have the right to opt-out of our databases at any time by contacting us. We will honour any opt-
                    out requests within 7 working days of receiving your email.<br />
                    If there is change in your primary contact details, you may notify us of the same and we will update
                    our databases accordingly.
                </p>
                <h2 style={{marginTop:'2rem'}}>10. PRIVACY QUESTIONS</h2>
                <p>
                    If you have any questions in regards with this policy or how as a user you engage with this website
                    or FlexFooty’s web application, please contact us at: admin@flexfooty.com
                </p>
            </div>
            <Footer />
        </div>
    )
}
