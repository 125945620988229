import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { OtpInput } from 'reactjs-otp-input';
import './otpverification.css'
import { ClipLoader } from 'react-spinners';
import toast from 'react-hot-toast';

export const Otpverification = () => {
  const location = useLocation();
  const [otp, setOtp] = useState('');
  const { email } = location.state || { email: "" };
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // useEffect(()=>{
  //   window.location.reload();
  // },[])

  const handleSubmit = async () => {
    setLoading(true);
    
    if (email.length === 0) {
      navigate('/admin/dashboard');
      setLoading(false);
      return;
    }

    let body = {
      email: email,
      otp: otp
    }

    const response = await fetch(process.env.REACT_APP_ENV_URL + '/api/auth/validateotp', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
      credentials: 'include'
    })
    console.log(response);
    if (response.status === 200) {
      setLoading(false);
      console.log(response);
      navigate('/admin/dashboard');
    }
    else {
      toast.error("Incorrect OTP");
      setLoading(false);
      return;
    }
  }

  return (
    <div className='otpContainer'>
      <h2>Please enter one time password sent to your email id</h2>
      <OtpInput
        containerStyle='otpBoxContainer'
        inputStyle='boxInput'
        skipDefaultStyles={true}
        value={otp}
        onChange={setOtp}
        numInputs={6}
        renderSeparator={<span>-</span>}
        renderInput={(props) => { <input {...props} /> }}
        isInputNum={true}
      />
      <button className='primaryButton' style={{ marginTop: '0' }} onClick={handleSubmit}>
        {
          loading ?
            <ClipLoader color='#fff' size={20} />
            :
            'Submit'
        }
      </button>
    </div>

  )
}
