import React from 'react'
import './legal.css'
import { Navbar } from '../../components/Navbar/navbar'
import { Footer } from '../../components/Footer/footer'

export const TermsAndConditions = () => {

    return (
        <div className='legalContainerPage'>
            <Navbar />
            <div className='legal_info_container'>
                <h1>Terms of Use, Policies and Conditions.</h1>
                <p>
                    This Website is owned and operated FlexFooty Limited used as “FlexFooty” (or “we” or “our” or “us”);
                    by entering, accessing or using our website (or other web application) in any way, you agree to
                    comply with the Website terms  and conditions of use which are outlined as below and any other laws
                    and regulations which apply to this website.
                    In addition, when you enquire about our services through this website, you will do so subject to the
                    terms and conditions, as set out in our terms and conditions and any further terms and conditions
                    which may be notified to you on the time of your enquiry.
                    FlexFooty Limited reserves the right to update these terms any time without prior notification, Website
                    users must agree upon and abide by these changes accordingly. Please review this page periodically
                    for changes. Any use of our site (or other web application) at any time constitutes your full acceptance
                    of our service terms.
                </p>
                <h2 style={{ marginTop: '2rem' }}>1. DESCRIPTION OF SERVICE</h2>
                <p>
                    FlexFooty facilitates 7-a-side and 8-a-side social football tournaments and leagues bringing people
                    who love the special game together to have a proper kick around.
                </p>
                <h2 style={{ marginTop: '2rem' }}>
                    2. PLAYERS &amp; RESTRICTIONS AROUND UNREGISTERED PLAYERS
                </h2>
                <p>
                    Team registration is confirmed only on receipt of full payment, and is on a first-in, first-served basis.
                    <br /><br />
                    The team manager accepts these terms and conditions on behalf of the team, including any person
                    that plays for them, at the time of team registration you will be required to register the players playing
                    for your team, ring-ins are strictly not allowed. If a player in your team is injured or withdraws prior to
                    start of the league or tournament you may be able to request a replacement for the injured player,
                    please make request for replacement of players at <a href="mailto:admin@flexfooty.com">play@flexfooty.com</a>
                    <br /><br />
                    If a team is caught of fielding any player(s), who are not registered with that team, it may result in a
                    point deduction for that team.
                    All participants of FlexFooty agree to:
                    <ul>
                        <li>Abide by the rules of FlexFooty to the best of their ability.</li>
                        <li>Respect the referees’ decision.</li>
                        <li>Contact FlexFooty as soon as possible in the event that their team decides to default prior to the
                            game.</li>
                    </ul>
                </p>
                <p>
                    Participants recognise that participation in FlexFooty activities is at their own risk and will not hold
                    FlexFooty Limited accountable for any injuries incurred.<br />
                    Participation in any FlexFooty activity implies that any photos or videos taken during such by any
                    party may be used by FlexFooty Limited for the purposes of promotion, or on their website or social
                    media sites. Players have the right to refuse a photo or video be taken and also to request removal of
                    their image from social media or the website if they so wish, and FlexFooty will oblige with such
                    requests.
                </p>
                <h2 style={{ marginTop: '2rem' }}>
                    3. PRICING, RETURNS AND CANCELLATION
                </h2>
                <h3 style={{ marginTop: '1.2rem' }}>Refunds</h3>
                <p>A refund will be offered in the case of withdrawal from competition prior to the first grading game,
                    minus an administration fee.<br />
                    No refund is available for teams that withdraw their entry after the start of the season.<br />
                    No refund is available for players or teams removed from FlexFooty Competitions for breaching the
                    terms and conditions or FlexFooty Rules.
                </p>
                <h3>Cancellation</h3>
                <p>
                    FlexFooty endeavours to avoid cancellation where possible. In the event of season disruption, games
                    are rescheduled where possible, however we may not be able to warranty a reschedule under some
                    circumstances.<br />
                    If FlexFooty competitions are cancelled prior to starting a league or tournament, we will endeavour to
                    run the full length of the original competition at alternative dates.<br />
                    Should a league season be disrupted by more than 40% and we are unable to offer alternative playing
                    dates, a pro-rata credit will be made available to affected teams.<br />
                    If we are unable to run the competition at all, refunds or credits will be available to teams.
                </p>
                <h2 style={{marginTop:'2rem'}}>4. INTELLECTUAL PROPERTY AND TRADEMARKS</h2>
                <p>
                    FlexFooty Limited is the owner and authorised user of all intellectual property including but not limited
                    to trademarks, logos, names and designs, whether registered or unregistered, and other marks that
                    are otherwise owned or licensed to FlexFooty Limited in and associated with this website (collectively
                    “intellectual property”) and any modifications or improvements to such intellectual property. The use or
                    misuse of any of FlexFooty's intellectual property is prohibited without the prior written consent
                    of FlexFooty Limited.<br/>
                    Nothing contained in this website should be construed as giving you any right or licence to any of the
                    intellectual property on or associated with this website.<br/>
                    The website and its contents are the property FlexFooty Limited and are subject to copyright. The
                    contents of our website and website as a whole are intended solely for your personal, non-commercial
                    use. Any use of our website and its content for purposes other than personal or non-commercial use
                    or for any other use, including modification, reproduction, distribution, transmission, republication, or
                    display of the contents of this website, is prohibited and protected under the copyright act, and any
                    such entity or individual can be prosecuted by law.
                </p>
            </div>
            <Footer/>
        </div>
    )
}
