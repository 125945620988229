import React, { useState, useEffect } from 'react'
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { IoIosArrowRoundForward } from "react-icons/io";
import toast from 'react-hot-toast';
import { fetchTeams } from '../../../../utils/getDataForDashboard';
import { useQuery } from '@tanstack/react-query';


export const CreateFixture = (props) => {

    const [teamA, setTeamA] = useState(undefined);
    const [teamB, setTeamB] = useState(undefined);
    const [matchDate, setMatchDate] = useState();
    const [matchTime, setMatchTime] = useState();

    useEffect(()=>{
        window.scrollTo(0,0);
    },[])
    const setCreateFixture = props.data;
    const { data: teams, isLoading, error } = useQuery({ queryKey: ["teams"], queryFn: fetchTeams });

    if (isLoading) return <p>Loading Fixtures</p>
    // loading component to be included
    if (error) {
        console.log(error.message);
        return;
    }

    const handleCreateFixture = async () => {
        const datePattern = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
        const timePattern = /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;

        if (teamA == undefined || teamB == undefined) {
            toast.error('Please select a team');
            return;
        }
        if (teamA === teamB) {
            toast.error('Team name cannot be same')
            return;
        }
        if (!datePattern.test(matchDate)) {
            toast.error('Date not in valid format');
            return;
        }
        if (!timePattern.test(matchTime)) {
            toast.error('Time not in valid format');
            return;
        }

        let body = {
            teamA: teamA,
            teamB: teamB,
            match_date: matchDate,
            match_time: matchTime
        }

        const response = await fetch(process.env.REACT_APP_ENV_URL + '/api/admin/createfixtures', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
            credentials: 'include'
        })

        if (response.status == 200) {
            toast.success('Fixture created successfully');
            setTimeout(() => {
                setCreateFixture(false);
            }, 3000);
        }
        else {
            const res = await response.json();
            toast.error(res.error);
            return;
        }
        return;
    }


    return (
        <div className='editFixtureContainer'>
            <button onClick={() => { setCreateFixture(false) }}>
                <MdOutlineKeyboardBackspace style={{ marginRight: '0.5rem' }} />
                Back
            </button>
            <div className='updateStatusSection'>
                <div>
                    <h2 style={{ marginBottom: '2rem' }}>Select Teams</h2>
                    <div>
                        <p>Select team A</p>
                        <IoIosArrowRoundForward size={18} />
                        <select className='select_box' onChange={(e) => { setTeamA(e.target.value) }}>
                            <option value=''></option>
                            {teams.map((team) => (
                                <option value={team.team_name}>{team.team_name}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <p>Select team B</p>
                        <IoIosArrowRoundForward size={18} />
                        <select className='select_box' onChange={(e) => { setTeamB(e.target.value) }}>
                            <option value=''></option>
                            {teams.map((team) => (
                                <option value={team.team_name}>{team.team_name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div>
                    <h2 style={{ marginBottom: '2rem' }}>Schedule Match</h2>
                    <div>
                        <p style={{ minWidth: '25%' }}>Match date</p>
                        <IoIosArrowRoundForward size={18} style={{ marginLeft: '1rem' }} />
                        <input type="text" style={{ fontSize:"0.8rem",width: '30%', border:'none' }} placeholder='dd/mm/yyyy' value={matchDate} onChange={(e) => { setMatchDate(e.target.value) }} />
                    </div>
                    <div>
                        <p style={{ minWidth: '25%' }}>Match time</p>
                        <IoIosArrowRoundForward size={18} style={{ marginLeft: '1rem' }} />
                        <input type="text" style={{ fontSize:"0.8rem",width: '30%', border:'none' }} placeholder='hh:mm:ss' value={matchTime} onChange={(e) => { setMatchTime(e.target.value) }} />
                    </div>
                </div>

            </div>
            <button className='createFixtureButton' style={{ position: 'relative', top: 0, left: 0 }} onClick={handleCreateFixture}>Create Fixture</button>
        </div>
    )
}
