import React from 'react'
import './footer.css'
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import { AiFillInstagram } from "react-icons/ai";
import instagramLogo from "./image.png";

export const Footer = () => {
    const isMobileScreen = window.innerWidth <= 1000 ? true : false;
    return (
        <div className='footerContainer'>
            <h2 onClick={() => { window.scrollTo(0, 0) }}>FlexFooty</h2>
            <p>All rights reserved.</p>
            <div className='footerIconsContainer'>
                <FaTwitter size={22} fill=' #1da1f2' style={{ cursor: 'pointer' }} />
                <a href="https://instagram.com/flexfooty.in" target="_blank" style={{ textDecoration: 'none' }}>
                    <img src={instagramLogo} style={{ marginTop: '0.4rem', width: isMobileScreen ? '22px' : '24px', height: isMobileScreen ? '22px' : '24px', cursor: 'pointer' }} />
                </a>
                <FaFacebook size={22} fill='#5890FF' style={{ cursor: 'pointer', backgroundColor: '#fff', borderRadius: '50%' }} />
            </div>
        </div>
    )
}
