import React, { useEffect, useState } from 'react'
import { SideNavBar } from '../../components/dashboard/sideNavBar'
import { InfoElements } from '../../components/dashboard/infoElements'
import { AdminLogin } from '../../components/dashboard/adminLogin';

const Dashboard = () => {
    const [token, setToken] = useState(false);
    // Check for token on component mount
    useEffect(async () => {
        const response = await fetch(process.env.REACT_APP_ENV_URL + '/api/auth/userLoggedIn', {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            credentials: 'include'
        });
        if (response.status === 200) {
            setToken(true);
        }
        else {
            setToken(false);
        }
    }, []);

    return (
        <>
            {token === false ? (
                <AdminLogin />
            ) : (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',
                    }}
                >
                    <SideNavBar />
                    <InfoElements />
                </div>
            )}
        </>
    );
}

export default Dashboard