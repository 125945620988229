import React from 'react'
import './venues.css'
import { fetchVenues } from '../../../../utils/getDataForDashboard'
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';

//importing icons
import { FaPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { CustomBarLoader } from '../../../loader/loader';

export const Venues = () => {

    const { data: venues, isLoading, error } = useQuery({ queryKey: ["Venues"], queryFn: fetchVenues });
    if (isLoading) return <CustomBarLoader/>
    if (error) return toast.error(error.message);

    const handleVenueDelete = async (id) => {
        if (id == null) {
            toast.error('Venue missing');
            return;
        }
        let body = { venue_id: id };
        const response = await fetch(process.env.REACT_APP_ENV_URL + '/api/admin/venue', {
            method: 'DELETE',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
            credentials: 'include'
        });

        if(response.status==200){
            toast.error('Venue Deleted...Please refresh the page');
            return;
        }
        else{
            toast.error("Venue selected by teams cannot be deleted");
            return;
        }

    }

    return (
        <div className='venuesScreen'>
            <button className='primaryButton createFixtureButton'>
                <FaPlus size={12} style={{ marginRight: '0.5rem' }} />
                Create Venue
            </button>
            <header>
                <p style={{ minWidth: '30%', textAlign: 'left' }}>Name</p>
                <p>Address</p>
            </header>
            {
                venues.map((venue, index) => (
                    <div key={index} className='venuesTableRow'>
                        <p style={{ minWidth: '30%', textAlign: 'left' }}>{venue.name}</p>
                        <p style={{ textAlign: 'left' }}>{venue.address}</p>
                        <MdDelete style={{ marginLeft: 'auto', cursor: 'pointer' }} size={20} fill='#D22B2B' onClick={() => { handleVenueDelete(venue.venue_id) }} />
                    </div>
                ))
            }
        </div>
    )
}

