import React from 'react'
import './aboutUs.css'
import { Navbar } from '../../components/Navbar/navbar.js';
import {Footer} from '../../components/Footer/footer.js';

export const AboutUs = () => {
    return (
        <div className='AboutUsScreen'>
            <Navbar />
            <h1>About FlexFooty</h1>
            <div className='aboutUsinfoContainer'>
                <p>FlexFooty is your go-to destination for exciting and professionally managed football
                    tournaments &amp; leagues. We are passionate about bringing together football lovers—whether
                    you’re an amateur, a weekend warrior, or an aspiring pro. Our mission is to provide players
                    of all levels a platform to flex their talent, build communities, and experience the joy of the
                    beautiful game.</p>
                <h3>Our Mission</h3>
                <p>At FlexFooty, we aim to:</p>
                <ul>
                    <li>Create seamless opportunities for football enthusiasts to participate in high-quality
                        competitions.</li>
                    <li>Promote grassroots football by making tournaments accessible to players from all
                        backgrounds.</li>
                    <li>Foster healthy competition, sportsmanship, and community engagement.</li>
                </ul>
                <h3>What We Offer</h3>
                <ul>
                    <li>Open for All: Whether you are part of a corporate team, college group, or local club,
                        we have categories for everyone.</li>
                    <li>Seamless Scheduling &amp; Management: Our user-friendly platform lets you register,
                        check fixtures, and track results easily.</li>
                    <li>Real-Time Updates: Stay informed with live scores, team standings, and match
                        statistics on the go.</li>
                </ul>
                <h3>Why Choose FlexFooty?</h3>
                <ul>
                    <li>Professional Management: Our events are organized by football experts who ensure
                        smooth operations and fair play.</li>
                    <li>Community Spirit: We believe in building lasting connections through the sport we
                        love.</li>
                </ul>
                <h3>Join Us on the Field</h3>
                <p>Whether you’re here to win trophies or just to have fun, FlexFooty ensures an unforgettable
                    footballing experience. Lace up your boots, gather your squad, and let the games begin!</p>
                <p>Stay connected, stay fit, and keep playing with FlexFooty—where football meets passion!</p>
            </div>
            <Footer/>
        </div>
    )
}
