import React from 'react'
import './divisions.css'
import { fetchDivisions } from '../../../../utils/getDataForDashboard'
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';

//importing icons
import { FaPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { CustomBarLoader } from '../../../loader/loader';

export const Divisions = () => {

    const { data: divisions, isLoading, error } = useQuery({ queryKey: ["Divisions"], queryFn: fetchDivisions });
    if (isLoading) return <CustomBarLoader/>
    if (error) return toast.error(error.message);

    return (
        <div className='venuesScreen'>
            <button className='primaryButton createFixtureButton'>
                <FaPlus size={12} style={{ marginRight: '0.5rem' }} />
                Create Division
            </button>
            <header>
                <p style={{ minWidth: '30%', textAlign: 'left' }}>Name</p>
                <p>Number of Rules</p>
            </header>
            {
                divisions.map((division, index) => (
                    <div key={index} className='venuesTableRow'>
                        <p style={{  minWidth: '30%',textAlign: 'left' }}>{division.name}</p>
                        <p style={{  textAlign: 'left' }}>{division.rules.length}</p>
                        <MdDelete style={{marginLeft:'auto', cursor:'pointer'}} size={20} fill='#D22B2B'/>
                    </div>
                ))
            }
        </div>
    )
}

