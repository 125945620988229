import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

export const AdminLogin = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate=useNavigate();
    const handleLogin = async (e) => {
        e.preventDefault();
        if (email.length === 0 || password.length === null) {
            toast.error("Fields cannot be empty");
            return;
        }
        let body = {
            email: email,
            password: password,
        }
        const response = await fetch(process.env.REACT_APP_ENV_URL + '/api/auth/loginadmin', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body),
            credentials: 'include'
        });
        if(response.status===200){
            navigate('/admin/dashboard/otpverification',{state:{email}});
            return;
        }else{
            toast.error("Invalid Login credentials");
            return;
        }

    }
    return (
        <form className='adminLoginForm' onSubmit={handleLogin}>
            <div className='input_wrapper'>
                <p>Email</p>
                <input type="email" placeholder='' value={email} onChange={(e) => { setEmail(e.target.value) }} />
            </div>
            <div className='input_wrapper'>
                <p>Password</p>
                <input type="password" placeholder='' value={password} onChange={(e) => { setPassword(e.target.value) }} />
            </div>
            <button className='primaryButton' type='submit'>Login</button>
        </form>
    )
}
