import { React, useState } from 'react'
import { Navbar } from '../../components/Navbar/navbar';
import { Footer } from '../../components/Footer/footer';
import { useAuthContext } from '../../context/authContext'
import './reviewInfo.css'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

export const ReviewInfo = () => {

    const { firstName, lastName, email, contactNumber, teamName, tournamentId, venueId, divisionId, players, managerFirstName, managerLastName, managerEmail, managerContactNumber, password, confirmPassword } = useAuthContext();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleRegister = async () => {
        setLoading(true);
        const body = {
            team_name: teamName,
            first_name: firstName,
            last_name: lastName,
            email_id: email,
            contact_number: contactNumber,
            password: password,
            confirm_password: confirmPassword,
            division_id: divisionId.value,
            venue_id: venueId.value,
            player_names: players,
            manager_name: `${managerFirstName} ${managerLastName}`,
            manager_email: managerEmail,
            manager_contact_number: managerContactNumber,
            tournament_id: tournamentId.value
        }
        try {
            const res = await fetch(process.env.REACT_APP_ENV_URL + '/api/user/registerteam', {
                method: 'POST',
                headers: { "Content-type": "application/json" },
                body: JSON.stringify(body),
                credentials: 'include'
            })
            if (res.status == 200) {
                toast.success("Team registered Successfully");
                setLoading(false);
                navigate('/');
                //add service to email them
            } else {
                const response = await res.json();
                setLoading(false);
                toast.error(response.error);
            }


        } catch (error) {
            console.log('Code came in catch block ', error.message);
            //replace with notification section
        }
    }
    const isMobileScreen = window.innerWidth <= 1000 ? true : false
    return (
        <div>
            <Navbar />
            <div style={{ width: '100%', minheight: '100vh', padding: '5%', paddingTop: isMobileScreen ? '10%' : '5%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <h1 style={{ marginBottom: isMobileScreen ? 30 : 50, fontSize: isMobileScreen ? '28px' : '' }}>Review</h1>
                <div className='info_wrapper'>
                    <div>
                        <p>Name</p>
                        <p>{firstName} {lastName}</p>
                    </div>
                    <div>
                        <p>Email</p>
                        <p>{email}</p>
                    </div>
                    <div>
                        <p>Contact</p>
                        <p>{contactNumber}</p>
                    </div>
                </div>
                <div className='info_wrapper'>
                    <div>
                        <p>Team Name</p>
                        <p>{teamName}</p>
                    </div>
                    <div>
                        <p>Tournament</p>
                        <p>{tournamentId.label}</p>
                    </div>
                    <div>
                        <p>Division</p>
                        <p>{divisionId.label}</p>
                    </div>
                    <div>
                        <p>Venue</p>
                        <p>{venueId.label}</p>
                    </div>

                </div>
                <div className='info_wrapper'>
                    <div>
                        <p>Manager Name</p>
                        <p>{managerFirstName} {managerLastName}</p>
                    </div>
                    <div>
                        <p>Email</p>
                        <p>{managerEmail}</p>
                    </div>
                    <div>
                        <p>Contact Number</p>
                        <p>{managerContactNumber}</p>
                    </div>
                </div>
                <button className='primaryButton' onClick={handleRegister}>
                    {!loading ?
                        'Register'
                        :
                        <ClipLoader color='#fff' size={18} speedMultiplier={0.7} />
                    }
                </button>
            </div>
            <Footer />
        </div>
    )
}
