import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query';
import { getFixturesForTeam } from '../../utils/helperFunctions';
import { fetchPointsTable } from '../../utils/getDataForDashboard';
import './teamInfo.css'
import { Navbar } from '../../components/Navbar/navbar';
import { Footer } from '../../components/Footer/footer';
import { CustomBarLoader } from '../../components/loader/loader';
import { ApiRateLimiterError } from '../../components/error/apiRateLimiterError';

export const TeamInfo = () => {

  const isMobileScreen = window.innerWidth <= 1000 ? true : false;
  const { teamName } = useParams();
  const navigate = useNavigate();
  const { data: teamFixturesRecord, isLoading, error } = useQuery({ queryKey: ["teamFixturesRecord"], queryFn: () => getFixturesForTeam(teamName) });
  const { data: pointsTable, isLoading2, error2 } = useQuery({ queryKey: ["pointsTable"], queryFn: fetchPointsTable });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  if (isLoading || isLoading2) return <CustomBarLoader />
  if (error) return <ApiRateLimiterError />;
  if (error2) return <ApiRateLimiterError />;


  const matchResult = (goala, goalb, teama, teamb, status) => {
    if (status === false) return "K";
    if (goala == goalb) return "D";
    if (goala > goalb && teama === teamName) return "W";
    if (goalb > goala && teamb == teamName) return "W";
    return "L";
  }
  const renderTeam = (team) => {
    if (team === teamName) return;
    navigate(`/team/${team}`);
    window.location.reload();
    return;
  }

  return (
    <div style={{ width: '100%', minHeight: '100vh', height: 'fit-Content' }}>
      <Navbar />
      <div className='teamInfoContainer'>
        <h2>{teamName}</h2>
        <p className='bottomBar'></p>
        <div className='infoSection'>
          <div className='section one'>
            <h2 style={{ marginBottom: '2rem' }}>Fixtures</h2>
            {teamFixturesRecord != undefined ? teamFixturesRecord.map((fixture, index) => (
              <div className='infoRow' key={index}>
                <p style={{ minWidth: '5%', fontFamily: 'CustomFont, sans-serif' }} className={matchResult(fixture.teama_goal, fixture.teamb_goal, fixture.teama, fixture.teamb, fixture.status)}>
                  {matchResult(fixture.teama_goal, fixture.teamb_goal, fixture.teama, fixture.teamb, fixture.status)}
                </p>
                <p style={{ minWidth: '40%', textAlign: 'left', marginLeft: '1rem', cursor: 'pointer' }} className='hoverHighlight' onClick={() => { renderTeam(fixture.teama === teamName ? fixture.teamb : fixture.teama) }}>
                  {fixture.teama === teamName ? fixture.teamb : fixture.teama}
                </p>
                {
                  fixture.status === false ?
                    <div style={{ display: 'flex', minWidth: '15%', backgroundColor: 'transparent', justifyContent: 'center', marginRight: isMobileScreen ? '1.5rem' : '3rem', borderRadius: '0.5rem', padding: '0.2rem' }}>
                        <p>TBP</p>
                    </div>
                    :
                    <div style={{ display: 'flex', minWidth: '15%', backgroundColor: '#0b0b0b', justifyContent: 'center', marginRight: isMobileScreen ? '1.5rem' : '3rem', borderRadius: '0.5rem', padding: '0.2rem' }}>
                      <p style={{ fontFamily: 'CustomFont, sans-serif' }}>{fixture.teama === teamName ? fixture.teamb_goal : fixture.teama_goal}</p>
                      <p style={{ margin: '0 0.3rem', fontFamily: 'CustomFont, sans-serif' }}>-</p>
                      <p style={{ fontFamily: 'CustomFont, sans-serif' }}>{fixture.teama === teamName ? fixture.teama_goal : fixture.teamb_goal}</p>
                    </div>
                }

                {isMobileScreen ? <></> : <p style={{ minWidth: '15%', textAlign: 'left' }}>{fixture.match_time}</p>}
                <p style={{ minWidth: isMobileScreen ? '30%' : '15%', textAlign: 'left' }}>{fixture.match_date.split('-').reverse().join('-')}</p>
              </div>
            )) : <></>}
          </div>
          <div className='section two'>
            <h2>Standings</h2>
            <div className='infoRow charBold' style={{ marginTop: '1rem' }}>
              <p></p>
              <p style={{ minWidth: isMobileScreen ? '30%' : '20%' }}></p>
              <p style={{ textAlign: 'center' }}>TP</p>
              <p style={{ textAlign: 'center' }}>GD</p>
              <p style={{ textAlign: 'center' }}>M</p>
              <p style={{ textAlign: 'center' }}>W</p>
              <p style={{ textAlign: 'center' }}>D</p>
              <p style={{ textAlign: 'center' }}>L</p>
            </div>
            {pointsTable != undefined ? pointsTable.map((item, index) => (
              <div key={index} className={`infoRow ${item.team_name === teamName ? 'charBold rowHighlight' : ''}`}>
                <p>{index + 1}</p>
                <p style={{ cursor:'pointer',minWidth: isMobileScreen ? '30%' : '20%' }} onClick={() => { renderTeam(item.team_name) }}>{item.team_name}</p>
                <p style={{ textAlign: 'center' }}>{item.total_points}</p>
                <p style={{ textAlign: 'center' }}>{item.goal_difference}</p>
                <p style={{ textAlign: 'center' }}>{item.played}</p>
                <p style={{ textAlign: 'center' }}>{item.won}</p>
                <p style={{ textAlign: 'center' }}>{item.draw}</p>
                <p style={{ textAlign: 'center' }}>{item.lost}</p>
              </div>
            )) : <></>}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
